function homeIntroSliderResize(){
	// $("#home-intro-slider .slides-container").height(
	// 	parseInt($("#home-intro-slider .slides-container .slides-img").outerHeight()) - 40
	// );

	// if(viewport().width > 992){
		// $("#home-intro-slider .slides-img .mask img").css({
		// 	width: $("#home-intro-slider .slides-img .mask:visible").outerWidth(),
		// 	height: $("#home-intro-slider .slides-img .mask:visible").outerHeight(),
		// })
	// }else{
	// 	$("#home-intro-slider .slides-img .mask img").width("100%").height("100%");
	// }
	
	if(viewport().width > 992){
		$("#home-full-banner .full-size").height(parseInt($(window).height()) - 150);
	}else{
		$("#home-full-banner .full-size").height(parseInt($(window).height()));
	}
}

homeIntroSliderResize();

$(window).on('resize', homeIntroSliderResize);

$("#home-intro-slider .slide-selector a, #home-intro-slider .m-slide-selector a, #home-intro-slider .bullets-container a").click(function(e){
	e.preventDefault();
	var category = $(this).data("category");
	var nextBgSlide = $("[data-slides-bg='" + category + "']");
	var currentBgSlide = $("[data-slides-bg]:visible");
	var nextIntro = $("[data-slides-intro='" + category + "']");
	var currentIntro = $("[data-slides-intro]:visible");
	var selectorContainer = $("#home-intro-slider .slide-selector");
	var selected = $(this).parent();
	var SelectoredLi = $(".slide-selector a[data-category='" + category + "']").parent();
	var mSelectoredLi = $(".m-slide-selector a[data-category='" + category + "']").parent();
	var mBullet = $(".bullets-container a[data-category='" + category + "']").parent();
	var mCaption = $("[data-slides-caption='" + category + "']");
	var currentCaption = $("[data-slides-caption]:visible");

	if(!$(this).parent(".active").length){
		$({})
		.queue(function (next) {
			$(".slides-img .mask", currentBgSlide).animate({
				width: 0,
				opacity: 0,
			}, 700, "easeInSine", next)
			$(".slides-img .mask", nextBgSlide).addClass("readyToShow").css({
				width:0,
				opacity:0,
			});
		})
		.queue(function (next) {
			currentBgSlide.hide();
			$(".slides-img .mask", currentBgSlide).attr("style", "");
			nextBgSlide.show();
			$(".slides-img .mask", nextBgSlide).animate({
				width: $(".slides-img", nextBgSlide).outerWidth(),
				opacity: 1,
			}, 700, "easeInSine", next)
		})
		.queue(function (next) {
			$(".slides-img .mask", nextBgSlide).removeClass("readyToShow").attr("style", "");
		})

		$({})
		.queue(function (next) {
			currentIntro.fadeOut(500, next);
			currentCaption.fadeOut(500, function(){
				currentCaption.removeClass('active');
			})
		})
		.queue(function (next) {
			nextIntro.delay(500).fadeIn(500, next);
			mCaption.delay(500).fadeIn(500);
			setTimeout(function(){
				mCaption.addClass('active');
			}, 500)
		})

		$({})
		.queue(function (next) {
			selectorContainer.fadeOut(500, next);
		})
		.queue(function (next) {
			SelectoredLi.addClass("active").siblings().removeClass("active");
			SelectoredLi.insertAfter($(".pick-slide li:last-child"));
			selectorContainer.fadeIn(500, next);
			mSelectoredLi.addClass("active").siblings().removeClass("active");
			mBullet.addClass("active").siblings().removeClass("active");
		})
		.queue(function (next) {
			selectorContainer.attr('style', '');
		})
	}
})

$(".feature-selector .features-circle").click(function(){
	$(this).addClass("active").siblings().removeClass("active");
	homeBannerSwiper.slideTo($(this).index());
})

var homeBannerSwiper = new Swiper('#home-full-banner .swiper-container', {
	allowTouchMove: false,
	effect: 'fade',
	initialSlide: 2,
	fadeEffect: {
	    crossFade: true
	},
	on: {
		slideChange: function(){
			// var slides = homeBannerSwiper.slides;
			// $(slides[this.activeIndex]).children().removeClass("stageIn");
		},
    	transitionEnd: function(){
    		if(homeBannerSwiper){
    			var slides = homeBannerSwiper.slides;
    			$(slides[this.activeIndex]).children().addClass("stageIn");
    			$(slides[this.previousIndex]).children().removeClass("stageIn");
    		}else{
    			$("#home-full-banner .swiper-container .swiper-slide").eq(2).children().addClass("stageIn");
    		}
    	}
    }
});

var homeArticleSwiper = new Swiper('.feature-main-blk .swiper-bg', {
	allowTouchMove: false,
	effect: 'fade',
	fadeEffect: {
	    crossFade: true
	},
	autoplay:{
    	delay: 3000
    },
	pagination: {
        el: '.feature-main-blk .swiper-bg .swiper-pagination',
        clickable: true,
    },
    on: {
    	slideChange: function(){
    		var index = this.realIndex;
    		homeArticleIntroSwiper.slideTo(index);
    	}
    },
});

var homeArticleIntroSwiper = new Swiper('.feature-main-blk .feature-news-content-container .swiper-container', {
	allowTouchMove: false,
	effect: 'fade',
	autoHeight: true,
	fadeEffect: {
	    crossFade: true
	},
})

$("#home-full-banner").css({
	opacity: 0
})

$("select[name='research-type']").change(function(){
	window.location.href = $(this).val();
})

$(window).scroll(function(){
	if(scrollTop > $("#homemain").offset().top){
		$("header").addClass("appear");
	}else{
		$("header").removeClass("appear");
	}
	if(isScrolledIntoView("#research-quick-preview")){
		$("#research-quick-preview").addClass("displayMenu");
	}
	if(isScrolledIntoView("#event-quick-preview")){
		$("#event-quick-preview").addClass("displayBlk");
	}
})

$(window).load(function() {
	$({})
	.queue(function (next) {
		$("#home-full-banner").delay(1000).fadeTo(1000, 1, next);
	})
	.queue(function (next) {
		$("#home-full-banner .banner-items").first().addClass("stageIn");
		$("#home-full-banner .feature-selector .features-circle").each(function(index, value){
			var _this = $(this);
			setTimeout(function(){
				_this.fadeTo(500, 1);
			}, 400 * (index + 1));
		})
	})
})